import React, { useState, useEffect } from 'react';
import { Container, Col } from 'react-bootstrap';
import Masonry from 'react-masonry-component';
import { graphql, useStaticQuery } from 'gatsby';

import { Section, Box, ListNav } from '../../components/Core';
import WorkCard from '../../components/WorkCard';
import { designWorks1 as designWorks2 } from '../../data';

const Works = ({ data }) => {
	const [items, setItems] = useState([]);
	const [activeLink, setActiveLink] = useState('*');

	useEffect(() => {
		setItems(designWorks2);
	}, []);

	const filterBy = (cat) => {
		if (cat === '*') {
			setActiveLink('*');
			setItems(designWorks2);
		} else {
			const filteredItems = designWorks2.filter((item) => {
				return item.categories.indexOf(cat) !== -1;
			});
			setActiveLink(cat);
			setItems(filteredItems);
		}
	};

	const masonryOptions = {
		transitionDuration: 1000,
	};

	return (
		<>
			{/* <!-- Works Area --> */}
			<Section className="position-relative">
				<Container>
					<Box mb="2.5rem" ml="-1.75rem">
						<ListNav className="nav">
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === '*' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('*');
									}}
								>
									Vsi projekti
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'embalaža' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('embalaža');
									}}
								>
									Embalaža
								</a>
							</li>

							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'cgp' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('cgp');
									}}
								>
									Celostna grafična podoba
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'oblikovanje' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('oblikovanje');
									}}
								>
									Oblikovanje
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link font-weight-bold text-uppercase ${
										activeLink === 'trajnostno' ? 'active' : null
									}`}
									onClick={(e) => {
										e.preventDefault();
										filterBy('trajnostno');
									}}
								>
									Trajnostno
								</a>
							</li>
						</ListNav>
					</Box>
				</Container>

				<Container fluid>
					<Masonry
						options={masonryOptions}
						className={'masonry-grid row'} // default ''
					>
						{items.map((item, index) => (
							<Col lg="3" md="4" sm="6" key={index} className="filtr-item">
								<WorkCard workItem={item} mb="30px" link={item.link} />
							</Col>
						))}
					</Masonry>
				</Container>
			</Section>
		</>
	);
};

export default Works;
