import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-scroll';

import { Title, Button, Section, Box, Text } from '../../components/Core';

import { device } from '../../utils';
import imgL from '../../assets/image/png/main-portrait.png';

import './styles.scss';

const ImgRight = styled.img`
	max-width: 100%;
`;

const Hero = () => {
	return (
		<>
			{/* <!-- Hero Area --> */}
			<Section hero className="position-relative">
				<Container>
					<Row className="align-items-center">
						<Col lg="7" className="mb-5 mb-lg-0">
							<Box>
								<Text
									variant="tag"
									mb={4}
									className="text-uppercase typing-demo"
									color="heading"
									mb="40px"
								>
									GRAFIČNO OBLIKOVANJE. TRAJNOSTNA EMBALAŽA. ILUSTRACIJA.
									ZGODBA.
								</Text>
								{/* <Title variant="hero">Ko dizajn pripoveduje zgodbo ...</Title> */}
								<Title variant="hero">Ko dizajn pripoveduje zgodbo ...</Title>
							</Box>
						</Col>
						<Col lg="5" md="8" sm="9">
							<div className="text-center text-lg-right position-relative">
								<div className="img-main">
									<ImgRight src={imgL} alt="" />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</Section>
		</>
	);
};

export default Hero;
